import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import Navigation from '../navigation'
import { Helmet } from "react-helmet"

export default function AboutRoute(props: PageProps) {
  return (
    <>
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>U.I.WD. - Creative Studio</title>
        </Helmet>
        <Navigation about></Navigation>
      </Layout>
    </>
  )
}
